import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import cx from "classnames";

import BaseMoneyInput from "../../components/Inputs/BaseMoneyInput";
import BaseNumberInput from "../../components/Inputs/BaseNumberInput";
import Button from "../../components/Button";
import { useStyles as useStepStyles } from "../../../../utils/loan-application.styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gridRowGap: "48px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "24px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  marginTop48OnMobile: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "48px",
    },
  },
}));

const LoanAmountStep = ({ formik, handlePrevStep, handleNextStep }) => {
  const classes = useStyles();
  const stepClasses = useStepStyles();

  const onNextStep = async () => {
    const errors = await formik.validateForm();
    formik.setFieldTouched("loanQuiz.propertyEstimate", true);
    formik.setFieldTouched("loanQuiz.cashEquityTowardsProperty", true);
    formik.setFieldTouched("loanQuiz.loanTerms", true);
    if (
      errors?.loanQuiz?.propertyEstimate ||
      errors?.loanQuiz?.cashEquityTowardsProperty ||
      errors?.loanQuiz?.loanTerms
    ) {
      return;
    }
    handleNextStep();
  };

  const loanAmount = useMemo(() => {
    return (
      formik.values.loanQuiz.propertyEstimate -
      formik.values.loanQuiz.cashEquityTowardsProperty
    );
  }, [
    formik.values.loanQuiz.propertyEstimate,
    formik.values.loanQuiz.cashEquityTowardsProperty,
  ]);

  const onLoanAmountChange = async (value) => {
    if (value > formik.values.loanQuiz.propertyEstimate) {
      await formik.setFieldValue(
        "loanQuiz.propertyEstimate",
        value + formik.values.loanQuiz.cashEquityTowardsProperty
      );
    } else {
      await formik.setFieldValue(
        "loanQuiz.cashEquityTowardsProperty",
        formik.values.loanQuiz.propertyEstimate - value
      );
    }
  };

  const onPropertyEstimateChange = async (value) => {
    if (value <= formik.values.loanQuiz.cashEquityTowardsProperty) {
      await formik.setFieldValue("loanQuiz.cashEquityTowardsProperty", value);
    }
    await formik.setFieldValue("loanQuiz.propertyEstimate", value);
  };

  const onCashEquityTowardsPropertyChange = async (value) => {
    if (value > formik.values.loanQuiz.propertyEstimate) {
      await formik.setFieldValue("loanQuiz.propertyEstimate", value);
    }
    await formik.setFieldValue("loanQuiz.cashEquityTowardsProperty", value);
  };

  return (
    <Box classes={{ root: classes.container }}>
      <Box classes={{ root: classes.inputContainer }}>
        <BaseMoneyInput
          label="Property Estimate"
          value={formik.values.loanQuiz.propertyEstimate}
          onChange={(value) => onPropertyEstimateChange(value)}
          error={
            formik.touched.loanQuiz?.propertyEstimate &&
            Boolean(formik.errors.loanQuiz?.propertyEstimate)
          }
          helperText={
            formik.touched.loanQuiz?.propertyEstimate &&
            formik.errors.loanQuiz?.propertyEstimate
          }
          maxValue={10000000}
        />
        <BaseMoneyInput
          label="Cash/equity towards property"
          value={formik.values.loanQuiz.cashEquityTowardsProperty}
          onChange={(value) => onCashEquityTowardsPropertyChange(value)}
          error={
            formik.touched.loanQuiz?.cashEquityTowardsProperty &&
            Boolean(formik.errors.loanQuiz?.cashEquityTowardsProperty)
          }
          helperText={
            formik.touched.loanQuiz?.cashEquityTowardsProperty &&
            formik.errors.loanQuiz?.cashEquityTowardsProperty
          }
          maxValue={10000000}
        />
      </Box>
      <Box classes={{ root: classes.inputContainer }}>
        <BaseMoneyInput
          label="Loan amount"
          value={loanAmount}
          onChange={(value) => {
            onLoanAmountChange(value);
          }}
          maxValue={10000000}
        />
        <BaseNumberInput
          label="What loan term do you want?"
          endAdornment="yrs"
          maxValue={50}
          minValue={0}
          value={formik.values.loanQuiz.loanTerms}
          onChange={(value) =>
            formik.setFieldValue("loanQuiz.loanTerms", value)
          }
        />
      </Box>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        classes={{
          root: cx(
            stepClasses.navigationButtonsContainer,
            classes.marginTop48OnMobile
          ),
        }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>

        <Button
          endIcon={<NavigateNextIcon />}
          onClick={onNextStep}
          disabled={formik.values.loanQuiz.loanPurpose === ""}
        >
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default LoanAmountStep;
